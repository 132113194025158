<script setup lang="jsx">
import { createApp, ref, computed, getCurrentInstance, withModifiers, nextTick, defineEmits, onMounted, onUnmounted, defineProps, watch } from 'vue'
import { ArrowDown, CircleClose } from '@element-plus/icons-vue';
import { ElCascader } from 'element-plus'
// import { NCascader } from 'naive-ui';

const emit = defineEmits(['change', 'changeItemName'])
const props = defineProps({
  row: {
    type: Object,
    default: () => ({}),
    required: true,
  },
  businessIdKey: {
    type: String,
    default: 'businessId',
  }
})
const containerBox = ref()
const cascaderInstance = ref()
const { proxy } = getCurrentInstance()
// const showOptions = ref(true)
const nCascaderRef = ref()
// const innerData = ref()
const dynamicDownContainer = ref()
const filterInputVal = ref('')
const isMouseOverContainer = ref(false)
// const cascaderPanelRef = ref()

const options = computed(() => {
  const data = proxy.$store.getters['commons/businessType']['in']
  if (!filterInputVal.value) return data
  return getFilterOptions(JSON.parse(JSON.stringify(data)))
})
const cascaderProps = {
  label: 'name',
  value: 'sign',
  children: 'items',
}
const onlyKey = uuid()
const popperClass = `dunamic-down-${onlyKey}`
const isClickCascader = ref(false)

watch(options, () => {
  if (props.row[props.businessIdKey]) {
    getchangedData()
  }
})
watch(() => props.row, () => {
  if (props.row[props.businessIdKey]) {
    const addList = ['关联资产', '新增科目', '新增存货']
    const hasAdd = addList.some(item => props.row.itemName?.includes(item))
    if (!hasAdd) return
    getchangedData()
  }
}, { deep: true, })

onMounted(() => {
  document.body.addEventListener('mouseover', setIsClickCascader)
  document.body.addEventListener('click', unmountCascader)
  dynamicDownContainer.value?.addEventListener('mouseenter', setIsMouseOverContainerTrue)
  dynamicDownContainer.value?.addEventListener('mouseleave', setIsMouseOverContainerFalse)
})

onUnmounted(() => {
  document.body.removeEventListener('mouseover', setIsClickCascader)
  document.body.removeEventListener('click', unmountCascader)
  document.body.querySelector(`.${popperClass}`)?.removeEventListener('click', stopProCustom)
  document.body.querySelector(`.${popperClass}`)?.removeEventListener('mouseover', changeIsClickCascaderAndStop)
})

function setIsMouseOverContainerTrue() {
  isMouseOverContainer.value = true
}
function setIsMouseOverContainerFalse() {
  isMouseOverContainer.value = false
}

function getchangedData() {
  let ids = [props.row[props.businessIdKey] + '', props.row[props.businessIdKey] + '-' + props.row.itemId + '-' + props.row.fzhsId, ]
  const first = options.value.find(item => ids[0] === item.sign)
  if (!first) return
  const second = first.items.find(item => item.sign === ids[1])
  if (!second) return 
  emit('changeItemName', [first.name, second.name,])
}

function getFilterOptions(data) {
  const filterOptions = data.filter(item => item.name.includes(filterInputVal.value))
  filterOptions.forEach(item => {
    if (item.items) {
      item.items = getFilterOptions(item.items)
    }
  })
  return filterOptions
}

function setIsClickCascader() {
  isClickCascader.value = false
}
function unmountCascader() {
  cascaderInstance.value?.unmount()
  cascaderInstance.value = null
  // containerBox.value?.remove()
}

function renderCascaderPanel() {
  if (cascaderInstance.value) return
  let val = [props.row[props.businessIdKey] + '', props.row[props.businessIdKey] + '-' + props.row.itemId + '-' + props.row.fzhsId, ]
  if (!props.row[props.businessIdKey]) {
    val = []
  }
  cascaderInstance.value = createApp({
    render() {
      // return <NCascader
      //   ref={nCascaderRef}
      //   v-model:value={innerData.value}
      //   options={options.value}
      //   size="small" 
      //   filterable
      //   show={showOptions.value}
      //   onClick={withModifiers(() => {}, ['stop'])}
      //   label-field="name"
      //   value-field="sign"
      //   children-field="items"
      //   check-strategy='child'
      //   onUpdate:value={onChange}
      //   onBlur={onBlur}
      //   onFocus={onFocus}
      // />
      return <ElCascader modelValue={val} style={{width: '100%'}} ref={nCascaderRef} popper-class={popperClass} size="small" options={options.value} props={cascaderProps} filterable onBlur={onBlur} onChange={closeCascader} onClick={withModifiers(() => {}, ['stop'])} v-slots={{
        default: ({data}) => <>
          <span>{ data.name }</span>
          {data.unit && <span style="color:var(--themeColor,#17a2b8)"> ({ data.unit}) </span>}
          {data.coding && <span style="color:var(--themeColor,#17a2b8)"> ({ data.coding}) </span>}
        </> 
      }} />
      // return <ElCascaderPanel modelValue={innerData.value} ref={nCascaderRef} options={options.value} props={cascaderProps} onChange={closeCascader} onExpandChange={onExpandChange} v-slots={{
      //   default: ({data}) => <>
      //     <span>{ data.name }</span>
      //     {data.unit && <span style="color:var(--themeColor,#17a2b8)"> ({ data.unit}) </span>}
      //   </> 
      // }} />
    }
  })
  // createCascaderContainer()
  cascaderInstance.value.mount(containerBox.value)
  
  nextTick(() => {
    // calculateTopOrBottom()
    containerBox.value.querySelector('.el-input__inner').focus()
    // console.log(nCascaderRef.value.cascaderPanelRef, 'nCascaderRef.value.cascaderPanelRef')
    nCascaderRef.value.togglePopperVisible()
  })

  setTimeout(() => {
    document.body.querySelector(`.${popperClass}`).addEventListener('click', stopProCustom)
    document.body.querySelector(`.${popperClass}`).addEventListener('mouseover', changeIsClickCascaderAndStop)
    // dynamicDownContainer.value.querySelector('.el-input__wrapper').addEventListener('click', changeIsClickCascaderAndStop)
  })
}

// function onChange(val) {
//   showOptions.value = false
//   console.log(val, '111111111111')
//   setTimeout(() => {
//     const node = nCascaderRef.value.getCheckedData()
//     console.log(node, '2222222222')
//   }, 3000)
//   emit('change', val)
// }

// function onExpandChange() {
//   setTimeout(() => {
//     calculateTopOrBottom()
//   })
// }

function changeIsClickCascaderAndStop(e) {
  e.stopPropagation()
  isClickCascader.value = true
}

function stopProCustom(e) {
  e.stopPropagation()
}

function onBlur() {
  if (isClickCascader.value) return
  setTimeout(() => {
    unmountCascader()
  }, 50)
}

// function onFocus() {
//   showOptions.value = true
// }

// function createCascaderContainer() {
//   const box = document.createElement('div')
//   box.classList.add('dynamic-down-cascader-container')
//   box.id = onlyKey
//   box.addEventListener('click', (e) => {
//     e.stopPropagation()
//   })
//   document.body.appendChild(box)
//   containerBox.value = box
//   containerBox.value.addEventListener('mouseover', (e) => {
//     e.stopPropagation()
//     isClickCascader.value = true
//   })
//   containerBox.value.addEventListener('click', (e) => {
//     e.stopPropagation()
//   })
// }

function closeCascader(val) {
  const node = nCascaderRef.value?.getCheckedNodes(true)?.[0]
  // console.log(val, nCascaderRef.value?.getCheckedNodes(true), '111111111111111')
  emit('change', { ids: val, node, })
  unmountCascader()
}

// function calculateTopOrBottom() {
//   const insertParentRect = containerBox.value.getBoundingClientRect()
//   const dynamicDownRect = dynamicDownContainer.value.getBoundingClientRect()
//   const bodyRect = document.body.getBoundingClientRect()

//   if (bodyRect.bottom - dynamicDownRect.bottom > insertParentRect.height) {
//     containerBox.value.style.top = `${dynamicDownRect.bottom + 10}px`
//   } else {
//     containerBox.value.style.top = `${dynamicDownRect.top - insertParentRect.height - 10}px`
//   }
//   if (bodyRect.right - dynamicDownRect.left > insertParentRect.width) {
//     containerBox.value.style.left = `${dynamicDownRect.left}px`
//   } else {
//     containerBox.value.style.left = `unset`
//     containerBox.value.style.right = `10px`
//   }
// }

function uuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  })
}

function clearData() {
  emit('change', { ids: null })
}
</script>
<template>
  <div ref="dynamicDownContainer" class="dynamic-cascader">
    <input :value="row.itemName" class="dynamic-cascader-input" type="text" @focus="renderCascaderPanel" @blur="onBlur" @click.stop>
    <el-icon v-if="isMouseOverContainer && row.itemName && !nCascaderRef && !cascaderInstance" class="clear-data-icon" @click="clearData"><CircleClose /></el-icon>
    <el-icon v-else><ArrowDown /></el-icon>
    <div ref="containerBox" class="dynamic-box" @click.stop="renderCascaderPanel"></div>
  </div>
</template>
<style lang="scss" scoped>
.dynamic-cascader {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 24px;
  padding: 0 7px;
  border: 1px solid #b9b9b9;

  .dynamic-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  input.dynamic-cascader-input {
    display: block;
    flex: 1;
    color: #606266;
    outline: unset;
  }
  .clear-data-icon {
    position: relative;
    z-index: 5;
  }
}
:deep(.el-cascader){
  display: block !important;
  margin-top: -1px;
  margin-left: -1px;
}
</style>
<style lang="css">
.v-binder-follower-container {
  z-index: 3000 !important;
}
.dynamic-down-cascader-container {
  position: fixed;
  z-index: 3000;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
}
</style>
